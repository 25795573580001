import * as React from "react"
import styled from "styled-components"
import Image from "./images/hero.jpg"

const Box = styled.div`
  width: 50%;
  height: 100vh;
  overflow: hidden;
  top: 0;
  position: sticky;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }

  @media (max-width: 766px) {
    width: 100%;
    height: 100vw;
    position: static;
  }
`

const Ele = () => {
  return (
    <Box>
      <img src={Image} alt="" />
    </Box>
  )
}
export default Ele
