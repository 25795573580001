import * as React from "react"
import styled, { css } from "styled-components"

const Box = styled.div`
  padding-top: 1rem;

  a {
    display: inline-flex;
    background-color: black;
    height: 3.5rem;
    padding: 0 2rem;
    align-items: center;
    border-radius: 2rem;
    padding-right: 1.5rem;

    .label {
      font-weight: bold;
      font-size: 1rem;
      letter-spacing: -0.5px;
      color: #fff;
      text-transform: uppercase;
    }

    .icon {
      height: 1.8rem;
      width: 1.8rem;
      margin-left: 1rem;

      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      path {
        stroke: var(--c-mint-100);
      }
    }
  }

  ${props =>
    props.dark &&
    css`
      a {
        background-color: var(--c-mint-100);

        .label {
          color: #000;
        }

        .icon {
          path {
            stroke: #000;
          }
        }
      }
    `}
`

const Ele = ({ link, label, dark }) => {
  return (
    <Box dark={dark}>
      <a href={link} target="_blank" rel="noreferrer">
        <span className="label">{label}</span>
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
          >
            <path d="M5 12L19 12"></path>
            <path d="M12 5L19 12 12 19"></path>
          </svg>
        </div>
      </a>
    </Box>
  )
}
export default Ele
