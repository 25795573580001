import * as React from "react"
import styled, { css } from "styled-components"
import CtaBtn from "./cta-btn"
import SectionWrapper from "./section-wrapper"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import VideoFrame from "./video-frame"

const VideoWrapper = styled.div`
  padding-top: 3rem;
`

const Box = styled.section`
  background-color: var(--c-mint-100);
  padding: 3rem 5rem 6rem;
  color: var(--c-blue-100);

  @media (max-width: 766px) {
    padding: 2rem;
  }
`

const Bubble = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  overflow: hidden;

  ${props =>
    props.bottom &&
    css`
      bottom: 1rem;
      right: 1rem;
      width: 7rem;
      height: 7rem;
      top: auto;
      left: auto;
    `}

  @media (max-width: 766px) {
    display: none;
  }
`

const CtaWrapper = styled.div`
  padding-top: 2rem;
`

const Ele = () => {
  const featuredImagesQuery = useStaticQuery(graphql`
    query featureGoalsQuery {
      allFile(filter: { relativeDirectory: { eq: "goals" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  `)

  const featuredImagesList = featuredImagesQuery.allFile.edges
  return (
    <SectionWrapper theme="mint">
      <Bubble>
        <GatsbyImage image={getImage(featuredImagesList[11].node)} alt="" />
      </Bubble>
      <h1>Are you running towards your financial goals?</h1>
      <section>
        <p>
          Join over <strong>40,000</strong> Ugandans who have discovered another
          kind of marathon; one that can lead you to a future free of financial
          stress.
        </p>
        <p>
          Let XENO help you to put into practice good financial habits and
          empower you with tools designed to help you achieve your dream goals.
        </p>

        <VideoWrapper>
          <VideoFrame link="https://www.youtube.com/embed/WwmkspKUayw" />
        </VideoWrapper>
      </section>
      <CtaWrapper>
        <CtaBtn link="#goals" label="Get Started" />
      </CtaWrapper>
    </SectionWrapper>
  )
}
export default Ele
