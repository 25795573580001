import * as React from "react"
import styled, { css } from "styled-components"

const Box = styled.section`
  padding: 5vh 0;
  position: relative;

  @media (max-width: 1023px) {
    padding: 4vw 0;
  }

  .inner {
    padding: 3rem 5rem 6rem;
    position: relative;
    z-index: 1;
    border-radius: 1rem 0 0 1rem;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(-45deg, #00e2c200 60%, #00e2c2);
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 75%;
    }
  }

  @media (max-width: 766px) {
    padding: 5vw auto;

    .inner {
      padding: 2rem;
    }
  }

  ${props =>
    props.theme === "mint" &&
    css`
      color: var(--c-blue-100);

      .inner {
        background-color: var(--c-mint-100);

        &:after {
          background-image: linear-gradient(
            -45deg,
            var(--c-mint-100) 60%,
            #fff
          );
        }
      }
    `}

  ${props =>
    props.theme === "light" &&
    css`
      color: var(--c-blue-100);

      .inner {
        background-color: #fff;
      }
    `}
 
 ${props =>
    props.theme === "dark" &&
    css`
      color: #fff;

      .inner {
        background-color: #000;

        h1 {
          span {
            color: var(--c-mint-100);
          }
        }

        &:after {
          display: none;
        }
      }
    `}
`

const Ele = ({ children, theme }) => {
  return (
    <Box theme={theme}>
      <div className="inner">{children}</div>
    </Box>
  )
}
export default Ele
