import React from "react"
import Meta from "../utilities/seo"
import Footer from "../components/layout/footer"
import Header from "../components/layout/header"
import Main from "../components/layout/main"
import Wrapper from "../microsites/mtn-kampala-marathon/wrapper"
import Hero from "../microsites/mtn-kampala-marathon/hero"
import Content from "../microsites/mtn-kampala-marathon/content"

const Page = () => {
  return (
    <>
      <Meta
        title="MTN MoMo"
        description="Join over 40,000 Ugandans who have discovered another kind of marathon; one that can lead you to a future free of financial stress."
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/v1668788414/pexels-godisable-jacob-1013329_sgoogz.jpg",
          height: "1280",
          width: "853",
        }}
      />
      <Main>
        <Header />
        <Wrapper>
          <Hero />
          <Content />
        </Wrapper>
        <Footer />
      </Main>
    </>
  )
}

export default Page
