import * as React from "react"
import styled from "styled-components"
import SectionWrapper from "./section-wrapper"
import CtaBtn from "./cta-btn"
import VideoFrame from "./video-frame"

const Ctas = styled.div`
  display: flex;

  > div {
    margin-right: 1rem;
  }

  @media (max-width: 766px) {
    display: block;
  }
`

const VideoWrapper = styled.div`
  padding: 2rem 0;
`

const Ele = () => {
  return (
    <SectionWrapper theme="mint">
      <h1>
        <span>Licensed and Regulated</span> as a fund manager
      </h1>
      <section>
        <p>
          XENO is licensed and regulated as a Fund Manager by Uganda’s Capital
          Markets Authority and by the Uganda Retirement Benefits Regulatory
          Authority. XENO executes all client portfolios using regulated unit
          trust funds.
        </p>

        <VideoWrapper>
          <VideoFrame link="https://www.youtube.com/embed/tWh31jNQrbM?modestbranding=1" />
        </VideoWrapper>

        <p>
          Explore answers to questions most customers ask and learn how XENO can
          help you plan, save and invest for your financial goals.
        </p>
        <Ctas>
          <CtaBtn
            label="Chat"
            link="https://web.whatsapp.com/send?phone=256392177488&text&app_absent=0"
          />
          <CtaBtn
            label="How XENO Works"
            link="https://learn.myxeno.com/support/"
          />
        </Ctas>
      </section>
    </SectionWrapper>
  )
}
export default Ele
