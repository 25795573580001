import * as React from "react"
import styled, { keyframes } from "styled-components"
import CtaBtn from "./cta-btn"
import SectionWrapper from "./section-wrapper"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const pace = keyframes`
  0%{
    transform: 0;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
`

const GoalList = styled.div`
  height: 10rem;
  display: flex;
  flex-wrap: nowrap;
  animation: ${pace} 75s linear infinite;
`

const GoalImage = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  flex-shrink: 0;
  overflow: hidden;
  /* margin: 3rem; */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`

const GoalsBox = styled.div`
  overflow: hidden;
  width: calc(100% + 10rem);
  position: relative;
  left: -5rem;
  padding: 2rem 0;
`

const Ele = () => {
  const goalListQuery = useStaticQuery(graphql`
    query goalListImages {
      allFile(filter: { relativeDirectory: { eq: "goals" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  `)

  const goalImages = goalListQuery.allFile.edges

  return (
    <SectionWrapper theme="light">
      <h1 id="goals">Turn your dreams into reality</h1>
      <p>
        Do you dream of owning a home, starting a business, taking your child to
        university or even retiring comfortably? Do you know what it will cost
        you to achieve that goal?
      </p>
      <GoalsBox>
        <GoalList>
          {goalImages &&
            goalImages.map((image, index) => {
              return (
                <GoalImage key={index}>
                  <GatsbyImage image={getImage(image.node)} alt="" />
                </GoalImage>
              )
            })}
        </GoalList>
      </GoalsBox>
      <CtaBtn link="https://join.myxeno.com" label="Get a Free Plan" />
    </SectionWrapper>
  )
}
export default Ele
