import * as React from "react"
import styled from "styled-components"
import CtaBtn from "./cta-btn"
import SectionWrapper from "./section-wrapper"

const FeatureList = styled.div`
  display: flex;
  padding-top: 1.5rem;

  @media (max-width: 766px) {
    display: block;
    padding-bottom: 1.5rem;
  }
`

const FeatureIcon = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  border: solid 2px var(--c-mint-100);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 40%;
    height: 40%;
  }

  path,
  circle {
    stroke: #ffcc00;
  }
`

const Feature = styled.div`
  text-align: center;
  margin: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 766px) {
    margin: 0;
    flex-direction: row;

    span {
      text-align: left;
      padding-left: 1.5rem;
    }
  }

  span {
    display: block;
    font-size: 0.875rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    opacity: 70%;
  }
`

const CtaWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

const Ele = () => {
  return (
    <SectionWrapper theme="dark">
      <h1>
        Now on MoMo. Now <span>everywhere you go</span>
      </h1>
      <p>
        XENO’s partnership with MTN MoMo now makes it easy to stay on track
        along your investment journey by dialing *165*5*7#. Enjoy features like
      </p>

      <FeatureList>
        <Feature>
          <FeatureIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M4.93 4.93L19.07 19.07"></path>
            </svg>
          </FeatureIcon>
          <span>Free deposits and free withdrawals on MoMo</span>
        </Feature>
        <Feature>
          <FeatureIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
            >
              <path d="M17 2l4 4-4 4"></path>
              <path d="M3 11v-1a4 4 0 014-4h14M7 22l-4-4 4-4"></path>
              <path d="M21 13v1a4 4 0 01-4 4H3"></path>
            </svg>
          </FeatureIcon>
          <span>Automate deposits toward your goal from UGX 10k</span>
        </Feature>
        <Feature>
          <FeatureIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M12 6L12 12 16 14"></path>
            </svg>
          </FeatureIcon>
          <span>
            24/7 monitoring and tracking of your investment progress .
          </span>
        </Feature>
      </FeatureList>

      <CtaWrapper>
        <CtaBtn dark link="tel:*165*5*7%23" label="Dial *165*5*7#" />
      </CtaWrapper>
    </SectionWrapper>
  )
}
export default Ele
