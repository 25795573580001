import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 766px) {
    display: block;
  }
`

const Ele = ({ children }) => {
  return <Box>{children}</Box>
}
export default Ele
