import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  width: calc(100% + 10rem);
  position: relative;
  left: -5rem;

  div {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    background: gainsboro;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const Ele = ({ link }) => {
  return (
    <Box>
      <div>
        <iframe
          src={link}
          title="XENO Investment"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Box>
  )
}
export default Ele
