import * as React from "react"
import styled from "styled-components"
import Preface from "./preface"
import Goals from "./goals"
import Momo from "./xeno-mtn-momo"
import Support from "./support"

const Box = styled.div`
  width: 57%;
  background-color: #000f25;
  padding-top: 20vh;

  @media (max-width: 1023px) {
    width: 65%;
    padding-top: 12vw;
  }

  @media (max-width: 766px) {
    width: 100%;
    padding-top: 0;
    padding: 1rem;
    padding-right: 0;
  }

  h1 {
    font-size: 2.75rem;
    line-height: 1;
    padding-bottom: 6vh;

    @media (max-width: 766px) {
      font-size: 2rem;
    }
  }

  p {
    padding-bottom: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.6;

    @media (max-width: 766px) {
      font-size: 1rem;
    }
  }

  .section {
    margin: 7.5vh 0;
    padding: 3rem 5rem 6rem;
    @media (max-width: 766px) {
      margin: 5vw auto 5vw;
    }
  }
`

const Feature = styled.div`
  height: 40rem;
  background-color: red;
  margin: 7.5vh 0;

  @media (max-width: 766px) {
    margin: 5vw auto 5vw;
    height: 100vw;
  }
`

const Ele = () => {
  return (
    <Box>
      <Preface />
      <Goals />
      <Momo />
      <Support />
    </Box>
  )
}
export default Ele
